<template>
  <div>
    <div class="w-full">
      <div class="flex flex-col w-full">
        <div class="w-full">
          <label for="name" class="block text-sm font-medium text-gray-700">
            Name
          </label>
          <div class="mt-1">
            <input id="name" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.name" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <label for="email" class="block text-sm font-medium text-gray-700">
            Tracking Sheet Link
          </label>
          <div class="mt-1">
            <input id="tracking_sheet_link" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.track_sheet_link" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>
        <div class="w-full mt-5">
          <label for="email" class="block text-sm font-medium text-gray-700">
            Tracking Sheet Tab Name
          </label>
          <div class="mt-1">
            <input id="tracking_sheet_tab_name" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.track_sheet_tab_name" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <label for="email" class="block text-sm font-medium text-gray-700">
            Tracking Sheet Last Column
          </label>
          <div class="mt-1">
            <input id="tracking_sheet_last_column" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.track_sheet_last_column" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <label for="email" class="block text-sm font-medium text-gray-700">
            E-Mail
          </label>
          <div class="mt-1">
            <input id="email" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.email" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <label for="login_code" class="block text-sm font-medium text-gray-700">
            Login Code
          </label>
          <div class="mt-1 relative">
            <button @click="handleCopy" class="absolute inset-y-3 right-5  flex items-center ">

              <svg  class="h-5 w-5 text-brand-primary"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
              </svg>
            </button>
            <input readonly id="login_code" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.login_code" />

          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>
        <div class="w-full mt-5">
          <label for="api_key" class="block text-sm font-medium text-gray-700">
            API Key
          </label>
          <div class="mt-1">
            <input v-if="isApiRevealed" readonly id="api_key" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.api_key" />
            <button class="block" @click="isApiRevealed = true" v-if="!isApiRevealed"e>Click to Reveal API Key</button>
            <button class="block" @click="isApiRevealed = false" v-if="isApiRevealed"> Click to Hide API Key</button>
            <button class="block" @click="testApiKey">Test API Key</button>
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <div class="block text-sm font-medium text-gray-700">
            <span>
              Account Lock
            </span>
          </div>
          <button @click="updatedClient.is_locked = !updatedClient.is_locked" type="button" aria-pressed="false"
                  :class="updatedClient.is_locked ? 'bg-brand-primary' : 'bg-gray-200'"
                  class="bg-gray-200 mt-1 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary">
            <span class="sr-only">Use setting</span>
            <!-- On: "translate-x-5", Off: "translate-x-0" -->
            <span aria-hidden="true"
                  :class="updatedClient.is_locked ? 'translate-x-5' : 'translate-x-0'"
                  class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
          </button>
        </div>

        <div class="w-full mt-5">
          <div class="block text-sm font-medium text-gray-700">
            <span>
              Is Deployed
            </span>
          </div>
          <button @click="updatedClient.is_deployed === 0 ? updatedClient.is_deployed = 1 : updatedClient.is_deployed = 0" type="button" aria-pressed="false"
                  :class="updatedClient.is_deployed === 1 ? 'bg-brand-primary' : 'bg-gray-200'"
                  class="bg-gray-200 mt-1 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary">
            <span class="sr-only">Use setting</span>
            <!-- On: "translate-x-5", Off: "translate-x-0" -->
            <span aria-hidden="true"
                  :class="updatedClient.is_deployed === 1 ? 'translate-x-5' : 'translate-x-0'"
                  class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
          </button>
        </div>
        <div class="w-full mt-5">
          Last Updated: {{ parseDate(updatedClient.UpdatedAt)}}
        </div>



      </div>
    </div>


  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "UpdateClientForm",
  props: ['client',],
  data() {
    return {
      isApiRevealed: false,
      updatedClient: this.client
    }
  },
  methods: {
    testApiKey() {
      // TODO : IMPLEMENT METHOD
      console.log("Test API Key")
    },
    handleCopy() {
      var copyText = document.querySelector("#login_code");
      copyText.select();
      document.execCommand("copy");
    },
    parseDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    }
  }

}
</script>

<style scoped>

</style>
