<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center mx-auto py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full flex sm:max-w-md">
      <div class="inline-flex mx-auto" v-html="loginLogo"></div>

    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="attemptLogin">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="login-form">
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="login-form">
            </div>
          </div>

          <div class="flex items-center justify-between">


<!--            <div class="text-sm">-->
<!--              <a href="#" class="font-medium text-brand-primary hover:text-brand-alternate">-->
<!--                Forgot your password?-->
<!--              </a>-->
<!--            </div>-->

            <div class="text-sm" v-if="errors">
              <a href="#" class="font-bold text-red-800">
                Incorrect Username or Password
              </a>
            </div>
          </div>



          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
             shadow-sm text-sm font-medium text-white bg-brand-primary hover:bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2
              focus:ring-indigo-500">
              Sign in
            </button>
          </div>
        </form>


      </div>
    </div>
  </div>
</template>

<script>
import icons from "@/utils/icons";
import jwtDecode from "jwt-decode"
export default {
name: "Login.vue",
  data() {
    return {
      email: '',
      password: '',
      errors: false
    }
  },
  mounted() {
    console.log(process.env)
  },
  methods: {
    async attemptLogin() {
      let attempt;
      try {
        attempt = await axios.post(process.env.VUE_APP_API_BASE_URL+"/public/login", {
          email: this.email,
          password: this.password
        })
        if (attempt.status === 200) {
          localStorage.setItem(process.env.VUE_APP_JWT_STORAGE, attempt.data)
          this.$router.push({ name: "Clients"})
        } else {
          this.errors = true
        }
        attempt.status === 200 ? localStorage.setItem(process.env.VUE_APP_JWT_STORAGE, attempt.data) : this.errors = true

      } catch (e) {
        console.log(e)
        this.errors = true
      }
    }
  },
  computed: {
    loginLogo() {
      return icons.loginLogo;
    }
  }
}
</script>

<style scoped>

</style>
