<template>
  <button @click.prevent="actionHandler" type="button" class="inline-flex items-center px-6 py-3 border border-transparent text-base
                               font-medium rounded-md shadow-sm text-white bg-brand-primary hover:bg-brand-alternate focus:outline-none
                               focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
    {{ text }}
  </button>
</template>

<script>
export default {
name: "Button",
  props: ['text', 'actionHandler']
}
</script>

<style scoped>

</style>
