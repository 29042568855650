<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center mx-auto py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full flex sm:max-w-md">
      <div class="inline-flex mx-auto" v-html="loginLogo"></div>

    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h1 class="text-xl text-center mb-3">
          API Key Submission
        </h1>
        <nav aria-label="Progress">
          <ol class="space-y-4 md:flex md:space-y-0 md:space-x-8">
            <li class="md:flex-1">
              <!-- Completed Step -->
              <a href="#" class="group pl-4 py-2 flex flex-col border-l-4 border-brand-primary hover:border-brand-alternate md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                <span class="text-xs text-brand-primary font-bold uppercase group-hover:text-brand-alternate">Step 1</span>
                <span class="text-sm font-medium">Invite Code</span>
              </a>
            </li>

            <li class="md:flex-1">
              <!-- Current Step -->
              <a href="#" :class="currentStep > 1 ? 'border-brand-primary' : null" class="pl-4 py-2 flex flex-col border-l-4  hover:border-brand-alternate md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
                <span class="text-xs text-brand-primary font-semibold uppercase group-hover:text-brand-alternate">Step 2</span>
                <span class="text-sm font-medium">API Key</span>
              </a>
            </li>

            <li class="md:flex-1">
              <!-- Upcoming Step -->
              <a href="#" :class="currentStep > 2 ? 'border-brand-primary' : null"  class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-brand-alternate md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                <span class="text-xs text-brand-primary font-semibold uppercase group-hover:text-brand-alternate">Step 3</span>
                <span class="text-sm font-medium">Completed</span>
              </a>
            </li>
          </ol>
        </nav>
        <form class="space-y-6" @submit.prevent="completeStepOne" v-if="currentStep === 1">
          <div>
            <label for="invite_code" class="block text-sm font-medium text-gray-700">
              Invitation Code
            </label>
            <div class="mt-1">
              <input v-model="inviteCode" id="invite_code" name="email" type="text" autocomplete="email" required class="login-form">
            </div>
          </div>


          <div class="flex items-center justify-between" v-if="inviteError">
<!--            // TODO: ERROR MESSAGES-->
            <span v-html="errorMessage" class="text-red-600">Wrong Invite Code</span>
          </div>



          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
             shadow-sm text-sm font-medium text-white bg-brand-primary hover:bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2
              focus:ring-indigo-500">
              Next Step
            </button>
          </div>
        </form>

        <form class="space-y-6 mt-5" @submit.prevent="completeStepTwo" v-if="currentStep === 2">
          <div>
            <label for="api_key" class="block text-sm font-medium text-gray-700">
              Stripe API Key
            </label>
            <div class="mt-1">
              <input v-model="apiKey" id="api_key" name="api_key" type="text" autocomplete="email" required class="login-form">
            </div>
          </div>


          <div class="flex items-center justify-between" v-if="isValidatingKey">
            <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-8 w-8"></div>
            <span class="ml-5 font-semibold text-brand-primary">
              Please wait while we validate your API Key...
            </span>
          </div>
          <div class="flex items-center justify-between" v-if="validationSuccess">
<!--            <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-8 w-8"></div>-->
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-brand-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <!--            // TODO: ERROR MESSAGES-->
            <span class="ml-5 font-semibold text-brand-primary">
              Your Key Has succesfully been validated!
            </span>
          </div>

          <div class="flex items-center justify-between" v-if="inviteError">
            <!--            // TODO: ERROR MESSAGES-->
            <span v-html="errorMessage" class="text-red-600">Wrong Invite Code</span>
          </div>



          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
             shadow-sm text-sm font-medium text-white bg-brand-primary hover:bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2
              focus:ring-indigo-500">
              Next Step
            </button>
          </div>
        </form>

          <div class="py-10"  v-if="currentStep === 3">
            <div class="flex items-center justify-between">
              <!--            <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-8 w-8"></div>-->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-brand-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <!--            // TODO: ERROR MESSAGES-->
              <span class="ml-5 text-lg font-semibold text-brand-primary">
              Your API Key Has now been submitted!
            </span>
            </div>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import icons from "@/utils/icons";

export default {
name: "KeyForm",
  mounted() {
  },
  data() {
    return {
      currentStep: 1,
      inviteCode: '',
      inviteError: false,
      isValidatingKey: false,
      validationSuccess: false,
      errorMessage: '',
      apiKey: '',
      apiKeyErrors: false,
      client: null
    }
  },
  computed: {
    loginLogo() {
      return icons.loginLogo;
    },
  },
  methods: {
    async completeStepOne() {
      this.inviteError = false
      try {
        let results = await axios.get(process.env.VUE_APP_API_BASE_URL+"/public/clients/invite/"+this.inviteCode)
        if (results.data.error) {
          this.inviteError = true;
          this.errorMessage = results.data.error
        } else {
          this.client = results.data
          this.currentStep = 2
        }

      } catch (e) {
        this.errorMessage = "Wrong Invite Code"
        this.inviteError = true;
      }

      // this.currentStep = 2
    },

    async completeStepTwo() {
      this.isValidatingKey = true



      this.inviteError = false;
      let results = await axios.post(process.env.VUE_APP_API_BASE_URL+"/public/clients/verify", {
        client_id: this.client.ID,
        api_key: this.apiKey
      })

    if (results.data.error) {
      // this.isValidatingKey = false;
      // this.inviteError = true
      setTimeout(() => {
        this.isValidatingKey = false;
        this.inviteError = true;
        this.errorMessage = results.data.error

      }, 1500)
    } else {
      setTimeout(() => {
        this.isValidatingKey = false;
        this.currentStep = 3;
      })
    }

      // setTimeout(() => {
      //   this.isValidatingKey = false
      //   this.validationSuccess = true
      //   setTimeout(() => {
      //     this.currentStep = 3
      //   }, 500)
      // }, 2500)
    },
    completeStepThree() {

    }
  }
}
</script>

<style scoped>

</style>
