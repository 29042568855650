<template>
  <div>
    <div class="my-8 flex flex-row">
      <div class="mr-auto">
        <gravy-button
            :action-handler="handleCreateClient"
            id="create-client-button"
            text="Create New Client" />
      </div>
      <div class="ml-auto">
        <div class="relative inline-block text-left">
          <div>
            <button @click.prevent="sortExpanded = !sortExpanded" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                                         px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                                         focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-primary"
                    id="options-menu" aria-haspopup="true" aria-expanded="true">
              Sort By
              <!-- Heroicon name: chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>

          <!--
            Dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <transition name="sort-dropdown">
            <div
                v-if="sortExpanded"
                id="sort-dropdown"
                class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button @click.prevent="() => { sortByDate(); sortExpanded = false}" class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                >
                  Date Created
                </button>
                <button @click.prevent="() => { sortByName(); sortExpanded = false}"
                        class="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem">
                  Name
                </button>
                <button @click.prevent="() => { sortByEmail(); sortExpanded = false}"
                        class="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem">
                  Email
                </button>
                <button @click.prevent="() => { sortByUpdatedAt(); sortExpanded = false}"
                        class="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem">
                  Last Modified
                </button>
              </div>
            </div>
          </transition>

        </div>
      </div>
    </div>

    <div>
      <transition-group  class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"  name="list-shuffle" tag="ul">
        <ul v-for="client in allClients" :key="client.ID" >
          <li :key="client.ID" class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img class="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full" :src="client.gravatar" alt="">
              <h3 class="mt-6 text-gray-900 text-sm font-medium client-name">
                {{ client.name }}
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Email</dt>
                <dd class="text-gray-500 text-sm client-email">{{ client.email }}</dd>
                  <dt class="sr-only">Role</dt>
                  <dd class="mt-3">
                  <span class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full client-api_key block w-1/2 mx-auto mb-3" v-if="client.api_key.length > 0">
<!--                                {{ client.is_locked }}-->
                    Verified
                  </span>
                  <span class="px-2 py-1 text-red-800 text-xs font-medium bg-red-100 rounded-full w-1/2 mx-auto mb-3 block w-1/2 mx-auto mb-3" v-else>
<!--                                {{ client.is_locked }}-->
                    Unverified
                  </span>

                    <span class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full client-api_key block w-1/2 mx-auto mb-3" v-if="client.is_deployed > 0">
<!--                                {{ client.is_locked }}-->
                    Deployed
                  </span>
                    <span class="px-2 py-1 text-red-800 text-xs font-medium bg-red-100 rounded-full block w-1/2 mx-auto mb-3l" v-else>
<!--                                {{ client.is_locked }}-->
                    Not Deployed
                  </span>
                  </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <button
                      @click.prevent="() => activateModal('normal', 'Update', client)"
                      class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700
                    font-medium border border-transparent rounded-bl-lg hover:text-gray-500 hover:bg-brand-gray">
                    <!-- Heroicon name: edit -->
                    <svg class="w-5 h-f text-brand-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                      <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-3">Edit</span>
                  </button>
                </div>
                <div class="-ml-px w-0 flex-1 flex">
                  <button
                      @click.prevent="() => activateModal('danger', 'Delete', client)"
                      id="delete-client-button"
                      class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700
                    font-medium border border-transparent rounded-br-lg hover:text-gray-500 hover:bg-brand-gray">
                    <!-- Heroicon name: phone -->
                    <svg class="w-5 h-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-3">
                      Delete
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </transition-group>

    </div>
    <transition name="sort-dropdown">
      <modal v-if="modalIsActive"
             :type="modalType"
             :action-text="modalActionText"
             :action-callback="doModalAction"
             :cancel-callback="deactivateModal" >
        <template v-slot:modalHeading>
          <span v-if="modalType === 'danger'">
            Delete Client
          </span>
          <span v-if="modalType === 'normal' && !createClientMode">
            Update Client
          </span>
          <span v-if="createClientMode">
            Create Client
          </span>
        </template>

        <template v-slot:modalBody>
          <span v-if="modalType === 'danger'">
            Are you sure you want to delete this clienteeeeeeeee? <br />
            <span class="font-bold">
                NOTE: THIS ACTION CANNOT BE UNDONE!
            </span>
          </span>
          <span v-if="modalType === 'normal'">
            <update-client-form v-if="!createClientMode" :client="activeClient" />
            <create-client-form v-if="createClientMode" :client="newClient" />
          </span>
        </template>

      </modal>
    </transition>
  </div>

</template>

<script>
import UpdateClientForm from "@/components/UpdateClientForm";
import CreateClientForm from "@/components/CreateClientForm";
import Modal from "@/components/Modal";
import GravyButton from "@/components/GravyButton";
export default {
  name: "Clients",
  components: {Modal, CreateClientForm, UpdateClientForm, GravyButton},
  data() {
    return {
      modalIsActive: false,
      modalActionText: "Update",
      modalType: "normal",
      activeClient: null,
      createClientMode: false,
      sortExpanded: false,
      newClient: {
        name: '',
        email: ''
      }
    }
  },
  mounted() {
    this.fetchClients()
  },
  methods: {
    fetchClients() {
      this.$store.dispatch('fetchClients')
    },
    activateModal(type = "normal", actionText="Update", client = this.newClient) {

      this.modalActionText = actionText
      this.modalType = type
      this.activeClient = {...client}
      this.modalIsActive = true
    },
    deactivateModal() {
      this.modalIsActive = false
      this.createClientMode = false
      this.activeClient = null
    },
    doModalAction() {
      if (this.modalType === "normal") {
        // let result = await axios.put('/users')
        console.log(this.activeClient)
        if (this.createClientMode) {
          this.$store.dispatch('createClient', this.newClient).then(() =>  this.fetchClients())
        } else {
          this.$store.dispatch('updateClient', this.activeClient).then(() =>  this.fetchClients())
        }

        // await this.getUsers()
      } else if (this.modalType === 'danger') {
        this.$store.dispatch('deleteClient', this.activeClient).then(() =>  this.fetchClients())
      }
      this.deactivateModal()
    },
    handleCreateClient() {
      this.createClientMode = true
      this.activateModal()
    },
    sortByDate() {
      this.$store.commit('sortClientsByDate')
    },
    sortByName() {
      this.$store.commit('sortClientsByName')
    },
    sortByEmail() {
      this.$store.commit('sortClientsByEmail')
    },
    sortByUpdatedAt() {
      this.$store.commit('sortClientsByUpdatedAt')
    }
  },
  computed: {
    allClients() {
      return this.$store.state.clients
    },
  }
}
</script>

<style scoped>

</style>
