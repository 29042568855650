<template>
  <div>
    <div class="my-8 flex flex-row">
      <div class="mr-auto">
        <gravy-button :action-handler="handleCreateUser" text="Create New User" />
      </div>
      <div class="ml-auto">

      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <ul v-for="user in appUsers">
        <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
          <div class="flex-1 flex flex-col p-8">
            <img class="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full" :src="user.gravatar" alt="">
            <h3 class="mt-6 text-gray-900 text-sm font-medium user-name">
              {{ user.name }}
            </h3>
            <dl class="mt-1 flex-grow flex flex-col justify-between">
              <dt class="sr-only">Email</dt>
              <dd class="text-gray-500 text-sm user-email">{{ user.email }}</dd>
              <dt class="sr-only">Role</dt>
              <dd class="mt-3">
              <span class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full user-role">
                {{ user.role_name }}
              </span>
              </dd>
            </dl>
          </div>
          <div>
            <div class="-mt-px flex divide-x divide-gray-200">
              <div class="w-0 flex-1 flex">
                <button
                    @click.prevent="() => activateModal('normal', 'Update', user)"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700
                    font-medium border border-transparent rounded-bl-lg hover:text-gray-500 hover:bg-brand-gray">
                  <!-- Heroicon name: edit -->
                  <svg class="w-5 h-f text-brand-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                  </svg>
                  <span class="ml-3">Edit</span>
                </button>
              </div>
              <div class="-ml-px w-0 flex-1 flex">
                <button
                    @click.prevent="() => activateModal('danger', 'Delete', user)"
                    class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700
                    font-medium border border-transparent rounded-br-lg hover:text-gray-500 hover:bg-brand-gray">
                  <!-- Heroicon name: phone -->
                  <svg class="w-5 h-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                  </svg>
                  <span class="ml-3">Delete</span>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <modal v-if="modalIsActive"
           :type="modalType"
           :action-text="modalActionText"
           :action-callback="doModalAction"
           :cancel-callback="deactivateModal" >
      <template v-slot:modalHeading>
        <span v-if="modalType === 'danger'">
          Delete User
        </span>
        <span v-if="modalType === 'normal' && !createUserMode">
          Update User
        </span>
        <span v-if="createUserMode">
          Create User
        </span>
      </template>

      <template v-slot:modalBody>
        <span v-if="modalType === 'danger'">
          Are you sure you want to delete this user? <br />
          <span class="font-bold">
                      NOTE: THIS ACTION CANNOT BE UNDONE!
          </span>
        </span>
        <span v-if="modalType === 'normal'">
          <update-user-form v-if="!createUserMode" :user="activeUser" />
          <create-user-form v-if="createUserMode" :user="newUser" />
        </span>
      </template>

    </modal>
  </div>


</template>

<script>
import Modal from "@/components/Modal";
import UpdateUserForm from "@/components/UpdateUserForm";
import CreateUserForm from "@/components/CreateUserForm";
import GravyButton from "@/components/GravyButton";
export default {
  name: "Users",
  components: {CreateUserForm, UpdateUserForm, Modal, GravyButton},
  data() {
    return {
      modalIsActive: false,
      modalActionText: "Update",
      modalType: "normal",
      activeUser: null,
      createUserMode: false,
      newUser: {
        name: '',
        email: '',
        Role: 2,
        password: '',
        confirm_password: ''
      }
    }
  },
  async mounted() {
    await this.getUsers()
  },
  methods: {
    async getUsers() {
      this.$store.dispatch('fetchUsers')
    },
    activateModal(type = "normal", actionText="Update", user = this.newUser) {
      this.modalActionText = actionText
      this.modalType = type
      this.activeUser = {...user}
      this.modalIsActive = true
    },
    deactivateModal() {
      this.modalIsActive = false
      this.createUserMode = false
      this.activeUser = null
    },
   doModalAction() {
    if (this.createUserMode) {
      this.$store.dispatch('createUser', this.newUser).then(() => this.getUsers())
    }
    if (this.modalType === "normal") {
      // let result = await axios.put('/users')
      this.$store.dispatch('updateUser', this.activeUser).then(() =>  this.getUsers())

      // await this.getUsers()
      } else if (this.modalType === 'danger') {
        this.$store.dispatch('deleteUser', this.activeUser).then(() =>  this.getUsers())
    }
    this.deactivateModal()
    },
    handleCreateUser() {
      this.createUserMode = true
      this.activateModal()
    }
  },
  computed: {
    appUsers() {
      return this.$store.state.users
    }
  }
}
</script>

<style scoped>

</style>
