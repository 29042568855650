<template>
  <div id="app">
    <template v-if="displayDashboard">
      <sidebar>
        <template v-slot:pageContent>
          <vue-page-transition>
            <router-view />
          </vue-page-transition>
        </template>
      </sidebar>
    </template>
    <template v-else>
      <vue-page-transition name="fade-in-right">
        <router-view />
      </vue-page-transition>
    </template>
  </div>
</template>

<style>

</style>
<script>
import Sidebar from "@/components/Sidebar";
export default {
  components: {Sidebar},
  computed: {
    displayDashboard() {
      if (this.$route.name !== "Login" && this.$route.name !== "API Key Submission")
        return true
      return false

    }
  }
}
</script>
