import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import Users from "@/views/Users";
import Clients from "@/views/Clients";
import KeyForm from "@/views/KeyForm";

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'Home',
    beforeEnter: guardMyroute,
    meta: { title: 'Dashboard' },
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/users',
    name: 'Users',
    beforeEnter: guardMyroute,
    component: Users
  },
  {
    path: '/clients',
    name: 'Clients',
    beforeEnter: guardMyroute,
    component: Clients
  },
  {
    path: '/',
    name: 'API Key Submission',
    // beforeEnter: guardMyroute,
    component: KeyForm
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

function guardMyroute (to, from, next) {
  const token = localStorage.getItem(process.env.VUE_APP_JWT_STORAGE)
  let isAuthenticated = false;
  axios.get("/auth").then(resp => {
    console.log(resp)
    if (resp.status = 200) {
      localStorage.setItem(process.env.VUE_APP_JWT_STORAGE, resp.data)
      store.commit('setUser')
      next()
    } else {

    }

  }).catch(e => {
    console.log(e)
    localStorage.removeItem(process.env.VUE_APP_JWT_STORAGE)
    next({path: '/login'})
  })


}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
