<template>
  <div>
    <div class="w-full">
      <div class="flex flex-col w-full">
        <div class="w-full">
          <label for="name" class="block text-sm font-medium text-gray-700">
            Name
          </label>
          <div class="mt-1">
            <input id="name" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.name" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <label for="email" class="block text-sm font-medium text-gray-700">
            E-Mail
          </label>
          <div class="mt-1">
            <input id="email" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedClient.email" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>



      </div>
    </div>


  </div>
</template>

<script>
export default {
name: "CreateClientForm",
  props: ['client'],
  data() {
    return {
      updatedClient: this.client
    }
  }
}
</script>

<style scoped>

</style>
