export default {
    loginLogo: `
        <svg xmlns="http://www.w3.org/2000/svg" width="400" height="85" viewBox="0 0 400 85" fill="none">
            <g opacity="0.7" clip-path="url(#clip0)">
            <path d="M164.8 5.01357H179.244V54.6024C179.244 65.5802 177.166 71.9358 172.077 76.9136C166.877 82.1136 159.022 85.0024 149.889 85.0024C139.489 85.0024 130.933 81.5358 123.655 74.3691L132.789 65.1247C137.533 70.2136 142.611 72.5247 149.322 72.5247C159.144 72.5247 164.811 66.7469 164.811 56.6913V51.258C159.955 57.3802 153.711 60.3913 145.966 60.3913C131.289 60.3913 120.655 48.4802 120.655 32.1913C120.655 14.858 131.411 3.18024 147.466 3.18024C154.977 3.18024 160.177 5.72468 164.8 11.6136V5.01357ZM136.244 31.4802C136.244 40.7247 142.255 47.3136 150.689 47.3136C154.966 47.3136 159.122 45.4691 161.789 42.3469C163.522 40.2691 164.566 36.9136 164.566 32.8691C164.566 27.0913 163.3 23.158 160.633 20.3802C157.977 17.8358 154.277 16.2136 150.577 16.2136C142.144 16.2247 136.244 22.5802 136.244 31.4802Z" fill="#3B8678"/>
            <path d="M203.866 5.01138V12.8669C205.366 8.9336 206.41 7.32249 208.61 5.70027C210.921 4.07805 214.044 3.15582 217.277 3.15582C217.621 3.15582 218.199 3.15582 219.01 3.26693V18.2892C217.855 18.178 216.699 18.0558 215.888 18.0558C211.844 18.0558 208.144 19.9003 206.066 22.9114C204.333 25.3336 203.866 27.6447 203.866 32.7336V58.5114H189.188V5.01138H203.866Z" fill="#3B8678"/>
            <path d="M267.544 5.01138H282.221V58.5336H267.544V51.1336C262.577 57.4891 256.799 60.378 248.932 60.378C233.91 60.378 223.155 48.7003 223.155 32.4003C223.155 15.5225 234.477 3.15582 250.088 3.15582C257.366 3.15582 262.344 5.70027 267.544 11.8225V5.01138V5.01138ZM238.877 32.178C238.877 41.5447 244.999 48.5891 252.977 48.5891C261.188 48.5891 267.777 40.9558 267.777 31.7114C267.777 22.2336 261.766 15.5336 253.099 15.5336C244.777 15.5336 238.877 22.3558 238.877 32.178Z" fill="#3B8678"/>
            <path d="M340.833 5.01129L321.299 58.5335H306.155L286.844 5.01129H302.099L313.888 36.9113L325.799 5.01129H340.833V5.01129Z" fill="#3B8678"/>
            <path d="M383.944 5.01129H399.544L369.377 82.9224H353.655L363.711 57.2668L344.988 5.01129H360.588L371.455 36.8002L383.944 5.01129Z" fill="#3B8678"/>
            <path d="M91.8429 0H32.0763C14.4429 0 -0.301513 14.4333 -0.00151314 32.0667C0.287376 49.2 14.3207 63.0444 31.5207 63.0444H60.6985V84.4C60.6985 84.6111 60.8651 84.7778 61.0763 84.7778H65.2429C73.454 84.7778 81.8763 82.1778 88.3429 77.6556C95.5652 72.6 99.5429 65.8778 99.5429 58.7111V7.68889C99.5318 3.44444 96.0985 0 91.8429 0ZM90.4651 58.7222C90.4651 61.1 89.5096 65.7667 83.1429 70.2222C79.3429 72.8778 74.6429 74.6778 69.7874 75.3778V63.0444H75.7096C75.9207 63.0444 76.0874 62.8778 76.0874 62.6667V54.3444C76.0874 54.1333 75.9207 53.9667 75.7096 53.9667H31.9985C19.7874 53.9667 9.44293 44.4111 9.08738 32.2C8.7096 19.5111 18.9207 9.07778 31.5207 9.07778H90.4651V58.7222Z" fill="#3B8678"/>
            <path d="M75.2117 33.983C76.8473 31.3363 76.0276 27.8649 73.3809 26.2293C70.7342 24.5938 67.2627 25.4134 65.6272 28.0601C63.9916 30.7068 64.8113 34.1783 67.458 35.8139C70.1047 37.4494 73.5761 36.6297 75.2117 33.983Z" fill="#3B8678"/>
            <path d="M51.5556 36.6556C54.6668 36.6556 57.189 34.1335 57.189 31.0223C57.189 27.9111 54.6668 25.389 51.5556 25.389C48.4444 25.389 45.9223 27.9111 45.9223 31.0223C45.9223 34.1335 48.4444 36.6556 51.5556 36.6556Z" fill="#3B8678"/>
            <path d="M38.25 31.9322C38.7484 28.8611 36.6628 25.9675 33.5917 25.4691C30.5207 24.9706 27.627 27.0562 27.1286 30.1273C26.6302 33.1983 28.7158 36.092 31.7868 36.5904C34.8579 37.0888 37.7515 35.0033 38.25 31.9322Z" fill="#3B8678"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="400" height="85" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    `
}
