import Vue from 'vue'
import Vuex from 'vuex'
import jwtDecode from "jwt-decode";

Vue.use(Vuex)


export default new Vuex.Store({
  strict: true,
  state: {
    user: {},
    users: [],
    clients: [],
    clientSortFields: [
        'CreatedAt',
        'name',
        'email'
    ],
    userSortFields: []
  },

  mutations: {
    setUser(state) {
      state.user = jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT_STORAGE))
    },
    setUsers(state, users) {
      state.users = users
    },
    setClients(state, clients) {
      state.clients = clients
    },
    sortClientsByDate(state) {
      state.clients.sort(function (a, b) {
        return new Date(b.CreatedAt) - new Date(a.CreatedAt)
      })
    },
    sortClientsByName(state) {
      state.clients.sort(function (a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    sortClientsByEmail(state) {
      state.clients.sort(function (a, b) {
        return a.email.localeCompare(b.email)
      })
    },
      sortClientsByUpdatedAt(state) {
        state.clients.sort(function (a, b) {
          return new Date(b.UpdatedAt) - new Date(a.UpdatedAt)
        })
      }
  },
  actions: {
    async updateUser(state, user) {
      user.role = parseInt(user.role)
      let result = await axios.put('/users/'+user.ID, user)
    },
    async createUser(state, user) {
      let result = await axios.post("/users", user)
    },
    async fetchUsers({ commit }) {
      let result = await axios.get("/users")
      commit('setUsers', result.data)
    },
    async deleteUser({ commmit }, user) {
      let result = await axios.delete("/users/"+user.ID)
    },
    async fetchClients({commit}) {
      let result = await axios.get("/clients")
      commit('setClients', result.data)
    },
    async updateClient(state, client) {

      let result = await axios.put('/clients/'+client.ID, client)
    },
    async deleteClient(state, client) {
      let result = await axios.delete('/clients/'+client.ID, client)
    },
    async createClient(state, client) {
      let result = await axios.post('/clients', client)
    }
  },
  modules: {
  }
})
