<template>
  <div>
    <div class="w-full">
      <div class="flex flex-col w-full">
        <div class="w-full">
          <label for="name" class="block text-sm font-medium text-gray-700">
            Name
          </label>
          <div class="mt-1">
            <input id="name" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedUser.name" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <label for="email" class="block text-sm font-medium text-gray-700">
            E-Mail
          </label>
          <div class="mt-1">
            <input id="email" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedUser.email" />
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>
        <div class="w-full mt-5">
          <label for="role" class="block text-sm font-medium text-gray-700">
            Role
          </label>
          <div class="mt-1">
            <select id="role" name="role" v-model="updatedUser.Role"
                    class="shadow-sm focus:ring-brand-alternate focus:border-brand-alternate border-2 p-3 block w-full sm:text-sm
                     border border-brand-primary rounded-md focus:outline-none">
              <option :value="1">SuperAdmin</option>
              <option :value="2">CRM</option>
              <!--              <option>Mexico</option>-->
            </select>
          </div>
          <!--          <p class="mt-2 text-sm text-gray-500">-->
          <!--            Brief description for your profile. URLs are hyperlinked.-->
          <!--          </p>-->
        </div>

        <div class="w-full mt-5">
          <label for="password" class="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div class="mt-1">
            <input type="password" id="password" name="about" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate focus:ring-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedUser.password" />
          </div>
          <p class="mt-2 font-bold text-sm text-gray-500">
          </p>
        </div>

        <div class="w-full mt-5">
          <label for="confirm_password" class="block text-sm font-medium text-gray-700">
            Confirm Password
          </label>
          <div class="mt-1">
            <input type="password" id="confirm_password" name="confirm_password" rows="3" class="w-full shadow-sm border-brand-primary border-2 p-3
            focus:border-brand-alternate focus:ring-brand-alternate block w-full sm:text-sm rounded-md focus:outline-none"
                   v-model="updatedUser.confirm_password" />
          </div>
          <p class="mt-2 font-bold text-sm text-gray-500">
          </p>
        </div>


      </div>
    </div>


  </div>
</template>

<script>
export default {
name: "CreateUserForm",
  props: ['user'],
  data() {
    return {
      updatedUser: this.user
    }
  }
}
</script>

<style scoped>

</style>
