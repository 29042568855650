import { render, staticRenderFns } from "./CreateUserForm.vue?vue&type=template&id=67c5f76c&scoped=true&"
import script from "./CreateUserForm.vue?vue&type=script&lang=js&"
export * from "./CreateUserForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c5f76c",
  null
  
)

export default component.exports