<template>
  <div class="h-screen flex overflow-hidden-bg-white">
    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div class="flex items-center flex-shrink-0 px-4">
              <svg class="h-8 w-auto m-auto" xmlns="http://www.w3.org/2000/svg" width="250" height="54" viewBox="0 0 250 54" fill="none">
                <path d="M103.114 3.13699H112.151V34.1649C112.151 41.0338 110.851 45.0105 107.667 48.1251C104.414 51.3788 99.4984 53.1863 93.7836 53.1863C87.2763 53.1863 81.923 51.0172 77.3693 46.533L83.0841 40.7487C86.0527 43.9329 89.2299 45.379 93.429 45.379C99.5748 45.379 103.121 41.7638 103.121 35.472V32.0723C100.082 35.903 96.1752 37.7871 91.3295 37.7871C82.1455 37.7871 75.4922 30.3343 75.4922 20.1422C75.4922 9.2967 82.222 1.98987 92.268 1.98987C96.9677 1.98987 100.221 3.58194 103.114 7.26664V3.13699ZM85.2462 19.6973C85.2462 25.4816 89.0074 29.6043 94.2842 29.6043C96.9608 29.6043 99.5609 28.4502 101.229 26.4966C102.314 25.1965 102.968 23.0969 102.968 20.5663C102.968 16.9511 102.175 14.49 100.506 12.752C98.8449 11.1599 96.5297 10.1449 94.2146 10.1449C88.9379 10.1518 85.2462 14.1285 85.2462 19.6973Z" fill="#3B8678"/>
                <path d="M127.557 3.13564V8.05089C128.496 5.58979 129.15 4.58171 130.526 3.56668C131.972 2.55165 133.926 1.97461 135.949 1.97461C136.164 1.97461 136.526 1.97461 137.033 2.04413V11.4436C136.31 11.3741 135.587 11.2976 135.08 11.2976C132.549 11.2976 130.234 12.4517 128.934 14.3357C127.849 15.8513 127.557 17.2974 127.557 20.4815V36.6108H118.374V3.13564H127.557Z" fill="#3B8678"/>
                <path d="M167.401 3.13564H176.585V36.6247H167.401V31.9945C164.293 35.9712 160.678 37.7788 155.756 37.7788C146.356 37.7788 139.627 30.4719 139.627 20.273C139.627 9.71248 146.711 1.97461 156.479 1.97461C161.033 1.97461 164.147 3.56668 167.401 7.39738V3.13564V3.13564ZM149.464 20.1339C149.464 25.9947 153.295 30.4024 158.286 30.4024C163.424 30.4024 167.547 25.6262 167.547 19.8419C167.547 13.9116 163.786 9.71943 158.363 9.71943C153.156 9.71943 149.464 13.9881 149.464 20.1339Z" fill="#3B8678"/>
                <path d="M213.258 3.13556L201.036 36.6246H191.56L179.477 3.13556H189.023L196.399 23.0955L203.852 3.13556H213.258V3.13556Z" fill="#3B8678"/>
                <path d="M240.233 3.13556H249.994L231.119 51.8848H221.281L227.573 35.8321L215.858 3.13556H225.619L232.419 23.026L240.233 3.13556Z" fill="#3B8678"/>
                <path d="M57.4642 0H20.068C9.03475 0 -0.190908 9.03099 -0.00319703 20.0642C0.177562 30.7846 8.95828 39.4472 19.7204 39.4472H37.977V52.8094C37.977 52.9415 38.0813 53.0458 38.2134 53.0458H40.8205C45.9582 53.0458 51.2281 51.419 55.2743 48.5894C59.7932 45.4261 62.2822 41.22 62.2822 36.7358V4.81097C62.2752 2.1552 60.127 0 57.4642 0ZM56.6022 36.7427C56.6022 38.2305 56.0043 41.1505 52.0206 43.9383C49.6429 45.5999 46.7021 46.7262 43.664 47.1642V39.4472H47.3695C47.5016 39.4472 47.6059 39.3429 47.6059 39.2108V34.0035C47.6059 33.8714 47.5016 33.7672 47.3695 33.7672H20.0193C12.3788 33.7672 5.90623 27.7882 5.68376 20.1477C5.44738 12.2082 11.8365 5.68 19.7204 5.68H56.6022V36.7427Z" fill="#3B8678"/>
                <path d="M47.058 21.2633C48.0814 19.6073 47.5685 17.4352 45.9125 16.4118C44.2564 15.3884 42.0843 15.9013 41.0609 17.5574C40.0376 19.2134 40.5504 21.3855 42.2065 22.4089C43.8625 23.4323 46.0346 22.9194 47.058 21.2633Z" fill="#3B8678"/>
                <path d="M32.2563 22.9356C34.203 22.9356 35.7811 21.3575 35.7811 19.4108C35.7811 17.4641 34.203 15.886 32.2563 15.886C30.3096 15.886 28.7315 17.4641 28.7315 19.4108C28.7315 21.3575 30.3096 22.9356 32.2563 22.9356Z" fill="#3B8678"/>
                <path d="M23.9309 19.9801C24.2428 18.0585 22.9378 16.248 21.0162 15.9361C19.0947 15.6242 17.2841 16.9292 16.9722 18.8507C16.6604 20.7723 17.9653 22.5829 19.8869 22.8948C21.8085 23.2066 23.619 21.9017 23.9309 19.9801Z" fill="#3B8678"/>
              </svg>
            </div>
            <nav class="mt-5 flex-1 px-2 bg-white space-y-1">
              <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
<!--              <router-link to="/dashboard" class=" group flex items-center px-2 py-2 text-sm font-medium rounded-md">-->
<!--                &lt;!&ndash; Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" &ndash;&gt;-->
<!--                &lt;!&ndash; Heroicon name: home &ndash;&gt;-->
<!--                <svg class="text-gray-500 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />-->
<!--                </svg>-->
<!--                Dashboard-->
<!--              </router-link>-->

              <router-link v-if="currentUser.role < 2" to="/users" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                <!-- Heroicon name: users -->
                <svg class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                Manage Users
              </router-link>

              <router-link to="/clients" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                <!-- Heroicon name: folder -->
                <svg class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                </svg>
                Manage Clients
              </router-link>
            </nav>
          </div>
          <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
            <button @click="handleLogout" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 w-full group flex items-center px-2 py-2 text-sm font-medium rounded-md">

              <svg  class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Open sidebar</span>
          <!-- Heroicon name: menu -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">
              {{ $route.name }}
            </h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <!-- Replace with your content -->
            <transition name="page">
              <slot name="pageContent" />
            </transition>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  methods: {
    handleLogout() {
      localStorage.removeItem(process.env.VUE_APP_JWT_STORAGE)
      this.$router.replace({ name: 'Login'})
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.user
    }
  }
}
</script>

<style scoped>

</style>
